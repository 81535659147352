$(function(){
  var window_w = window.innerWidth;
  var video = document.getElementById("video")
  var movie1_url = "https://my-rails-theclo.s3.ap-northeast-1.amazonaws.com/Video_330a91bc-ece2-45ae-b921-b6dfef0c2e47.mp4";
  var movie2_url = "https://my-rails-theclo.s3.ap-northeast-1.amazonaws.com/Video_f419ab5a-cf6d-4aeb-9925-9d107cb2e6db.mp4";
  if (window_w <= 500){
    video.src = movie1_url;
  } else if(window_w > 500){
    video.src = movie2_url;
  }

  $(window).on('load scroll',function (){
    //スクロール量を取得
    var scroll = $(window).scrollTop();
    //ウィンドウの高さを取得
    var height = $(window).height();
  	$('.animation, .animation_left, .animation_right').each(function(){
  		//ターゲットの位置を取得
  		var target = $(this).offset().top;
  		//ターゲットまでスクロールするとフェードインする
  		if (scroll > target - height){
  			//クラスを付与
  			$(this).addClass('animation_active');
  		}
  	});
    $('.finish_text').each(function(){
      var target = $(this).offset().top;
      if (scroll > (target - height) - 200){
        const animationTargetElements = document.querySelectorAll(".textAnimation");
        for(let i = 0; i < animationTargetElements.length; i++){
          const targetElement = animationTargetElements[i],
                texts = targetElement.textContent,
                textsArray = [];

          targetElement.textContent = "";
          for(let j = 0; j < texts.split("").length; j++){
            const t = texts.split("")[j];
            textsArray.push('<span style="animation-delay: '+ (j * .1) +'s;">' + t + '</span>');
          }
          for(let k = 0; k < textsArray.length; k++){
            targetElement.innerHTML += textsArray[k];
          }
        }
        $(this).removeClass('finish_text');
      }
    });
  });
});
